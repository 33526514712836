export default function Footer() {
  return (
    <footer className="footer mt-0 lg:pt-6">
      <div className="pt-6 lg:mt-8">
        <div className="w-11 mx-auto">
          <div className="flex flex-column md:flex-row gap-2 justify-content-between flex-wrap">
            <div className="flex-1">
              <div>
                <img src="/insuright_logo_dark.svg" alt="logo" />
              </div>
              <p>
                It is an Insurance Broker licensed under IRDA. Ever since its
                inception in 2023, the organisation has been growing steadily
                and phenomenally under the leadership of a group of committed
                individuals
              </p>
              <h3>Address</h3>
              <p>
                Above ICICI Bank, 3rd Floor, No. 1/33-5, Chancellors Chanmber,
                Ulsoor Road, Bangalore - 560008
              </p>
            </div>

            {/* <div className="flex-1 lg:pl-8 min-w-max">
              <h4 className="mt-0">Quick links</h4>
              <ul className="list-none p-0 white-text">
                <li className="mb-3">
                  <a href="#">About Our Company</a>
                </li>
                <li className="mb-3">
                  <a href="#">Careers</a>
                </li>
                <li className="mb-3">
                  <a href="#">Insurance Article</a>
                </li>
                <li className="mb-3">
                  <a href="#">Sitemap</a>
                </li>
                <li className="mb-3">
                  <a href="#">Contact Us</a>
                </li>
              </ul>
            </div>

            <div className="flex-1 min-w-max">
              <ul className="list-none p-0 white-text m-0">
                <li className="mb-3">
                  <div className="uppercase">Car Insurance</div>
                </li>
                <li className="mb-3">
                  <div className="uppercase">Two Wheeler Insurance</div>
                </li>
                <li className="mb-3">
                  <div className="uppercase">General Insurance</div>
                </li>
                <li className="mb-3">
                  <div className="uppercase">Health Insurance</div>
                </li>
              </ul>
            </div> */}
            <div className=" flex-1 min-w-max flex md:justify-content-center">
              <div>
                <h4 className="mt-0">Contact Us</h4>
                {/* <p>
                  <bold>Email</bold> : contact@insuright.com
                </p> */}
                <p>
                  <bold>Phone</bold> : +91 8147829997
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-container">
        <div className="w-11 mx-auto p-3">
          <div className="flex gap-2 align-items-center  justify-content-center flex-wrap">
            <p className="text-sm m-0">
              &copy; 2024 Insuright. All Rights Reserved
            </p>
            {/* <p className="text-sm m-0">
              <a href="#">Terms & Conditions</a>
              <a href="#" className="ml-6">
                Pricay & policy
              </a>
            </p> */}
          </div>
        </div>
      </div>
    </footer>
  );
}
