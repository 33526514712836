import logo from "./logo.svg";
import { Button } from "primereact/button";
import "./App.css";
import { ReactComponent as WhiteStar } from "./assets/icons/white_star.svg";
import services from "./utils/searvies.json";
import customerService from "./utils/asCutomer.json";

import cusomter_reviews from "./utils/customer_reviews.json";
import Header from "./views/Header";
import CustomerReviewCarousel from "./components/Carousels/CustomerReviewCarousel";
import DownloadInsurightApp from "./views/DownloadInsurightApp";
import Partners from "./views/Partners";
import Footer from "./views/Footer";

function App() {
  return (
    <div className="App">
      <Header />
      <main className="relative mb-8">
        <div className="bg-img">
          <div className="banner">
            <div className="w-11 m-auto flex flex-column justify-content-center banner-info-continer">
              <div className="banner-info w-full lg:w-8">
                <p className="m-0 text-3xl lg:text-5xl">Buy Your</p>
                <h1 className="m-0 text-5xl lg:text-7xl">INSURANCE</h1>
                <p className="m-0 text-3xl lg:text-5xl">
                  In a Smart Way, with{" "}
                  <span style={{ color: "#06335D" }}>One Click</span>
                </p>
                <small>
                  80% of our customers insure their bike online in less than 3
                  minutes. No Inspection required. It's that simple.
                </small>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute servies-nav w-11 lg:w-10 p-2">
          <div className="flex jutify-content-center align-items-center gap-1">
            {services?.map((item, i) => (
              <div
                key={item.id}
                className={`text-center service-card p-2 flex-1 ${
                  i == 2 && "active"
                }`}
              >
                <div className="p-2">
                  <img src={item.url} alt={item.title} width={40} height={40} />
                </div>
                <h3 className="m-0 text-xl uppercase">{item.title}</h3>
                <p className="mt-0 mb-2 text-lg text-color">{item.subTitle}</p>
              </div>
            ))}
          </div>
        </div>
      </main>
      <div className=" w-11 m-auto pt-4">
        <h1 className=" my-3 text-4xl lg:text-6xl">
          Why insuright Insurance Should Be Your{" "}
          <span style={{ color: "#03A67C" }}>Top Choice</span>
        </h1>
        <p className="w-full lg:w-9">
          Insuright Insurance offers customizable insurance plans tailored to
          your specific needs. Whether you're looking for auto, home, life,
          health, or business insurance, they provide options that fit your
          requirements and budget.
        </p>
        {/* <div className="py-3">
          <h4>As a Customer</h4>
          <div>
            <div className="customer-benifits flex gap-3 overflow-x-auto">
              {customerService?.map((item) => (
                <div key={item.id} className="flex-1 customer-benifit-card">
                  <img
                    src={item.url}
                    style={{
                      objectFit: "fill",
                      width: "100%",
                      height: "100%",
                    }}
                  />
                  <div className="w-full card-info-cont absolute z-3 p-2 flex flex-column justify-content-start">
                    <p className="text-center mt-0">{item.title}</p>
                    <p className="card-desc m-0">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="">
          <h4>As a POSP PARTNER</h4>
          <div>
            <div className="customer-benifits flex gap-3 overflow-x-auto">
              {customerService?.map((item) => (
                <div key={item.id} className="flex-1 customer-benifit-card">
                  <img
                    src={item.url}
                    style={{
                      objectFit: "fill",
                      width: "100%",
                      height: "100%",
                    }}
                  />
                  <div className="w-full card-info-cont  absolute z-3 p-2 flex flex-column justify-content-start">
                    <p className="text-center mt-0">{item.title}</p>
                    <p className="card-desc m-0">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div> */}

        {/* <div>
          <h1>
            What Our Customers{" "}
            <span style={{ color: "#03A67C" }}>Are Saying?</span>
          </h1>
          <div className="my-6 flex gap-5 flex-wrap justify-content-center">
            <CustomerReviewCarousel items={cusomter_reviews} />
          </div>
        </div> */}
      </div>
      {/* <div className="w-11 m-auto">
        <DownloadInsurightApp />
      </div>
      <Partners /> */}

      <div className="relative py-8">
        <div className="static lg:absolute footer-abs w-11 lg:w-10 py-4 px-4 lg:px-6">
          <div className="flex flex-column lg:flex-row justify-content-center gap-3 lg:justify-content-between align-items-center flex-wrap">
            <h1 className="white-text w-full lg:w-8 text-5xl m-0">
              Let’s Secure Your Future with Our Services{" "}
              <span>
                <WhiteStar width={32} />
              </span>
            </h1>
            <div className="mr-6">
              <Button
                label="Get Started"
                severity="secondary"
                outlined
                onClick={() => {}}
                className="bg-white"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
